.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .modal-title {
        .MuiTypography-root {
          font-family: var(--headers-font-family) !important;
          font-size: 28px;
          color: #ffffff;
          font-weight: normal;
          text-transform: capitalize;
          font-weight: bold;
        }
      }

      .MuiDialogTitle-root {
        background: var(--theme1-bg);
        color: var(--primary-color);
        padding: 1px 11px;
        text-align: left;
        height: 50px;
        color: #ffffff;
        font-weight: normal;
        text-transform: capitalize;
        .MuiTypography-root {
          color: var(--ion-text-light);
          font-weight: 600;
          text-transform: capitalize !important;
          font-family: var(--font-family);
          font-size: 20px;
          vertical-align: bottom;
          margin-top: 5px;
        }
      }
      .MuiIconButton-root {
        position: absolute;
        top: 0px;
        right: 0px;
        opacity: 1;
        color: var(--theme1-bg);
      }

      .light-bg-title {
        background: var(--ion-background-secondary);
        text-align: center;
        height: 60px;
        .MuiTypography-root {
          color: #fff;
          font-weight: normal;
          text-transform: capitalize !important;
        }
      }

      .MuiDialogContent-root {
        background: var(--primary-color);
        padding: 10px 28px;
        margin-right: 10px;

        .MuiButton-containedPrimary {
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-contras);
          text-transform: none;
          font-weight: 600;
        }
        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
      .MuiDialogActions-root {
        justify-content: center !important;
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogTitle-root {
    background: var(--theme1-bg);
    color: var(--primary-color);
    padding: 1px 11px;
    text-align: left;
    height: 5px;
    color: #ffffff;
    font-weight: normal;
    text-transform: capitalize;
  }
  .modal-close-btn .MuiIconButton-label svg {
    fill: #000 !important;
  }
}

.MuiDialog-paperWidthMd {
  width: 1185px !important;
}
.popover-content.sc-ion-popover-ios {
  border-radius: 2px !important;
}
