:root {
  --theme1-bg: #0088CC;
  --theme1-bg90: #0088CC;
  --theme2-bg: #2C3E50;
  --theme2-bg70: #2C3E50;
  --theme2-bg85: #2C3E50;
  --primary-color: #fff;
  --secondary-color: #fff;
  --premium-odd-background: #72e3a0;
  --scorecard-bg:linear-gradient(var(--theme1-bg), var(--theme2-bg));

  --theme-gradient: linear-gradient(var(--theme1-bg), var(--theme2-bg));
  --grey-bg: #ccc;
  --site-color: #000000;
  --back-odd-background-light: #adcde5;
  --back-odd-background-rgb: 151, 211, 255;
  --lay-odd-background-rgb: 255, 173, 173;
  --lay-odd-background-light: #e9b9c7;
  --odds-font-color: #273a47;
  /* --odds-font-color: #000; */

  --font-family: "Roboto Condensed", sans-serif;

  --back-odd-background: #72bbef;
  --lay-odd-background: #faa9ba;
  --back-odd-background1: rgba(114, 187, 239, 0.5);
  --lay-odd-background2: rgba(250, 169, 186, 0.75);
  --back-odd-background2: rgba(114, 187, 239, 0.75);
  --lay-odd-background1: rgba(250, 169, 186, 0.5);

  --ion-color-primary: #2C3E50;
  /* --ion-color-primary: #992e65;
  --ion-color-primary-rgb: 255, 167, 36;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 10, 13, 21;
  --ion-color-primary-shade: #e40a7b;
  --ion-color-primary-tint: #be196f;
  
  --home-page-img-bg-color: #202638; */

  /** secondary **/
  /* --ion-color-secondary: #37446e;
  --ion-color-secondary-rgb: 55, 68, 110;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #303c61;
  --ion-color-secondary-tint: #4b577d; */

  /** tertiary **/
  --ion-color-tertiary: #2C3E50;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2C3E50;
  --ion-color-tertiary-tint: #2C3E50;

  /** success **/
  --ion-color-success: #34a300;
  --ion-color-success-rgb: 52, 163, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2e8f00;
  --ion-color-success-tint: #48ac1a;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff6262;
  --ion-color-danger-rgb: 255, 98, 98;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e05656;
  --ion-color-danger-tint: #ff7272;
  --ion-color-lost: #cf0a13;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cbd4f0;
  --ion-color-light-tint: #f5f6f9;

  /** Sports List Navbar Colors **/
  --ion-nav-card-background: #373b4b;
  --ion-nav-card-text: #fff;

  /** Header Colors **/
  --ion-header-text-color: #828db9;
  --ion-header-text-color-rgb: 130, 141, 185;
  --ion-header-background: #373b4b;
  --ion-header-link-color: rgba(255, 255, 255, 0.6);

  /** Back and Lay **/

  /** Table colour **/
  --table-headers-light: rgba(32, 32, 32, 0.6);
  --headers-font-family: "Roboto Condensed", sans-serif;
  --table-header-background: #1f2536;
  --table-body-background: #363b4b;
  --table-border-color: #1f2536;

  /* Odds info icons */
  --market-info-icons: var(--ion-color-primary);

  /*
   * White Label variables
   * ---------------------
   */
  --odd-btn-animation-color: #ffa724;
  --footer-text-color: #6a7394;
  --nav-list-live-icon-color: #ff0000;

  /* Home page */
  --homepage-bg-color: #202638;

  /* offers section */
  --light-text-color: #8792b3;

  /** Header Logo Sizes **/
  --logo-xl-width: 116px;
  --logo-xl-height: 46px;
  --logo-lg-width: 116px;
  --logo-lg-height: 46px;
  --logo-md-width: 64px;
  --logo-md-height: 26px;
  --logo-sm-width: 94px;
  --logo-sm-height: 46px;
  --logo-xs-width: 74px;
  --logo-xs-height: 46px;

  /* Login logo */
  --login-logo-width: 320px;
  --login-logo-height: 76px;
}

/*
 * Light Colors
 * ------------------
 */
.ios body,
.md body {
  --ion-background: #fff;
  --ion-background-color-rgb: 0, 20, 61;
  --ion-background-color: #fff;
  --ion-background-secondary: #ec407a;
  --ion-background-tertiary: #ec407a;

  --linear-gradient-background: linear-gradient(
    180deg,
    rgba(216, 77, 148, 0.8) 0%,
    rgba(138, 36, 89, 0.8) 100%
  );
  --linear-gradient-background-1: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 86%
  );
  --linear-gradient-background-2: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 117.02%
  );

  --score-card-background: linear-gradient(
    88.06deg,
    #00143d 30%,
    var(--theme1-bg) 86%
  );
  --webkit-gradient-background-1: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 86%
  );
  --webkit-gradient-background-2: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 117.02%
  );

  --ion-text-color: #00143d;
  --ion-text-color-rgb: 0, 20, 61;
  --ion-text-light: #000;
  --ion-text-light-secondary: #c5c5c5;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;
  --ion-text-color-contrast: var(--ion-text-dark);
  --ion-text-color-contrast-rgb: var(--ion-text-dark-rgb);

  /** Card Variables**/
  --ion-card-background: #373b4b;
  --ion-card-text-color: #00143d;
  --ion-card-background-contrast: #373b4b;
  --ion-card-border: 1px solid rgba(10, 13, 21, 0.1);
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /** Popover Variables**/
  --ion-popover-background: var(--ion-background);
  --ion-popover--hover-background: #1f2536;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #ffffff;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #e4e8ed;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #e4e8ed;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-element-background: var(--ion-popover--hover-background);
  --select-popover-background: var(--ion-popover-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-header-background: #202d57;
  --dialog-content-background: #1f2536;

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #1f2536;
  --date-picker-header-button-background: #1f2536;

  /** Drawer Styles **/
  --drawer-background: #e4e8ed;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #00143d;

  /** Input Field Styles **/
  --input-background-color: #fff;
  --input-text-color: #ffffff;
  --input-border-color: #ffffff;

  /** Reports style **/
  --reports-bg-color: #373b4b;
  --reports-title-color: var(--ion-color-primary);
  --filter-controls-border: #373b4b;

  /* Odds info icons */
  --market-info-icons: var(--ion-color-primary);

  /* Scorecard */
  --score-card-details-color: var(--ion-color-primary);

  /* Markets Accordian*/
  --accordian-headers-color: #fff;
}

.ios body {
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body {
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
}

ion-title {
  --color: #ffffff;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
