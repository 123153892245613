.MuiPopover-root {
  .MuiPopover-paper {
    background: var(--primary-color);   font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bold;
    .MuiList-root,
    .MuiMenu-list {
      padding: 0px;
      .MuiListItem-root,
      .MuiMenuItem-root {
        color: var(--site-color);
        font-size: 14px;
        height: 40px;
        padding-left: 4px;
        &:not(:last-child) {
          border-bottom: 1px solid var(--grey-bg);
        }
        &:hover {
          background: var(--theme2-bg);
        }
        &.Mui-selected {
          color: var(--site-color);
          background: var(--ion-popover--hover-background);
          // border-left: 4px solid var(--ion-color-primary);
        }
      }
    }
  }
}
.popover-arrow.sc-ion-popover-ios:after {
  opacity: 0 !important;
}