.accept-terms-checkbox {
  color: var(--ion-text-light) !important;
  position: relative !important;
}

.password-ctn {
  .title-row {
    margin-top: 8px;
    width: 100%;
    background-color: var(--theme2-bg);
    padding: 7px;
    margin-left: 10px;
    .title {
      width: 100%;
      background-color: var(--theme2-bg);
      color: var(--secondary-color);
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.change-pwd-form-ctn {
  .accept-terms-input {
    .MuiIconButton-label {
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .error-msg{
    color :red;
  }
  .success-msg{
    color:green;
  }
}
