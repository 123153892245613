$assetPath: '../../assets/images/sportsbook/background';

#bg {
  background-image: linear-gradient(var(--theme1-bg), var(--theme2-bg));
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  .center-content {
    position: absolute;
    margin-left: 37%;
    top: 10%;
    .logo {
      margin-bottom: var(--login-logo-mleft);
      margin-left: var(--login-logo-mbottom);
      padding-left: 30px;
      width: var(--login-logo-width);
      height: var(--login-logo-height);
    }

    .logo_loading {
      display: block;
      justify-content: center;
      .logo {
        height: 60px;
      }
      .logo-text {
        text-align: center;
        margin-bottom: 4px;
        font-size: 12px;
        text-align: center;
        font-size: 13px;
        opacity: 0.9;
        font-family: -webkit-body;
      }
      .fa-spin {
        width: 100%;
        margin-top: 12px;
        font-size: 24px;
      }
    }
    .logo-text {
      text-align: center;
      margin-bottom: 5px;
      font-size: 13px;
      text-align: center;
      opacity: 0.9;
      font-family: -webkit-body;
    }
    .card {
      background-color: #fff;
      background-size: cover;
      border-radius: 8px;
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
      margin-top: 10px;
      box-shadow: 0 0 5px #fff;
      .MuiInputBase-root {
        border: 0px !important;
        padding: 5px;
        .MuiInputBase-input {
          color: #000 !important;
          background-color: #fff !important;
          padding: 5px;
        }
      }
      .MuiFormControl-root {
        background-color: #fff !important;
      }
    }
  }
}
@media (max-width: 600px) {
  #bg {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .center-content {
     top: 10px;
     margin-left: auto;
    }
  }
}
.footer-login {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--theme1-bg);
  color: #fff;
  text-align: center;
  padding: 0px 10px;


  .footer-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .footer-links ul li {
      width: 50%;
    }
  }

  @media only screen and (max-width: 767px) {
    .footer-links {
      width: 100%;
      text-align: center;
    }
    .nav-link{
      font-size: 12px;
    }
  }


  .footer-links .nav-link {
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    font-family: sans-serif;
  }

  .terms-condi-ctn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  @media only screen and (max-width: 767px) {
    .footer-links ul {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      display: flex;
    }

    .terms-condi-ctn {
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-top: 5px;
    }
  }

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .footer-link {
    color: #ffffff;
    /* White text color */
    text-decoration: none;
    /* Remove underline */
    font-weight: bold;
    /* Bold font for emphasis */
  }

  .footer-link:hover {
    text-decoration: underline;
    /* Underline on hover */
  }

  .footer-right {
    font-size: 24px;
    font-weight: 700;
    margin-right: 40%;
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.support-detail {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  flex: 1;

  @media only screen and (max-width: 767px) {
    .support-detail {
      width: 100%;
      margin-top: 15px;
    }
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  font-family: sans-serif;
}

.secure-logo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: normal;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.footer-bottom .secure-logo img {
  max-height: 100px;
}

.footer-bottom .secure-logo img {
  @media only screen and (max-width: 767px) {
    max-height: 60px;
  }
}

.footer-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
