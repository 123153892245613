.login-form-ctn {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 0%;
  width: 350px;
  .recaptcha-notice {
    font-weight: 600;
    font-size: 10px; /* Small font size */
    color: #000; /* Light gray text color */
    text-align: center; /* Center alignment */
    margin-top: 10px; /* Space above */
    line-height: 1.5; /* Slightly increased line height for readability */
    font-family: var(--font-family);
  }
  
  .recaptcha-notice a {
    color: #1a73e8; /* Google blue color for links */
    text-decoration: none; /* Remove underline */
  }
  
  .recaptcha-notice a:hover {
    text-decoration: underline; /* Underline on hover for accessibility */
  }  
  .form-title {
    color: var(--theme1-bg);
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    padding: 9px;
  }
  .input-control {
    margin-bottom: 1.5rem !important;
    border-radius: 0%;
  }

  .login-and-demologin {
    align-items: center;
    .demo-login {
      margin-top: 2%;
    }
    .login-form-btn {
      display: flex;
      // justify-content: space-between;
      width: 100%;
      background-color: var(--theme1-bg);
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      font-size: 1rem;
      border-radius: 0px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-transform: capitalize;
      .icon {
        color: var(--input-text-color);
        right: 0%;
      }
      .fa-android {
        margin-left: 10px;
      }
      .ml-5 {
        color: var(--input-text-color);
        margin-right: 5px;
        letter-spacing: 0;
        width: 90%;
      }
    }

    .login-without-demoLogin {
      width: 100%;
    }
  }

  .recaptchaTerms {
    font-size: 82%;
    font-weight: bold;
    color: var(--site-color) !important;
    margin: 6px 0;
    font-family: 'Roboto Condensed', sans-serif !important;
    .nav-item {
      text-decoration: none;
      line-height: 1.6;
      font-family: 'Roboto Condensed', sans-serif !important;
    }
  }

  .bottom-link {
    margin-top: auto;
    margin-bottom: 1rem;
    font-size: 14px;
    text-align: center;

    .register-link {
      color: var(--ion-color-primary);
      cursor: pointer;
    }
  }

  .usr-input,
  .login-input-field {
    height: 35px;
    width: 100%;
    // margin: auto 4px;
    input {
      height: 100%;
      width: 100%;
      font-size: 1rem !important;
      padding: 5px !important;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: bold;
    }

    input::placeholder {
      color: #6c757d;
      font-size: 14px;
      padding: 5px !important;
      opacity: 1;
      font-weight: bold;
      font-family: 'Roboto Condensed', sans-serif;
    }
  }

  .pwd-input {
    // margin-top: 1rem;
    .pwd-field {
      height: 35px;
      width: 100%;
      //   margin: auto 4px;
      .MuiOutlinedInput-root {
        border-radius: 0%;
      }
      .MuiInputBase-root {
        padding: 0% !important;
      }
      .input-icon .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px; /* Adjust width and height as needed */
        height: 33px;
        border: 1px solid #d3d3d3; /* Border color */
        background-color: #E9ECEF; /* Optional: background color */
        cursor: pointer; /* Add pointer cursor for better UX */
      }
      
      .input-icon .icon-container i {
        padding-right: 0px;
        color: #000; /* Icon color */
        font-size: 16px; /* Adjust icon size */
      }
      input {
        height: 100%;
        width: 100%;
        font-size: 12px !important;
        padding: 5px !important;
      }
      input::placeholder {
        color: #6c757d;
        font-size: 14px;
        padding: 5px !important;
        opacity: 1;
      }
      .input-icon {
        background: #fff;
      }
    }
  }
  .icon-title {
    margin-top: 3rem;
    padding-left: 0%;
    ion-img {
      width: var(--logo-xl-width);
      height: var(--logo-xl-height);
    }
  }
}

@media screen and (max-width: 721px) {
  .login-form-ctn {
    .input-control {
      .MuiInputBase-root {
        border-radius: 0px !important;

        fieldset {
          border: 0;
          border-bottom: 1px solid var(--theme2-bg);
        }
      }
    }
  }
}