// .tbl-ctn {
//   .MuiTableContainer-root {
//     background-color: inherit;
//     .MuiTable-root {
//       .MuiTableHead-root {
//         border: none;
//       }
//     }
//   }
// }
// .MuiPaper-root {
//   margin-top: 16px;
//   box-shadow: none;
//   background-color: var(--ion-background) !important;
//   border-color: none;
//   // width: 100%;
//   border-radius: 6px;
//   .MuiTable-root {
//     border: none;
//     background-color: inherit;
//   }
//   .MuiTableHead-root {
//     height: 31px;
//     background-color: var(--table-header-background);
//     border: 1px solid var(--table-border-color);
//     .MuiTableRow-head {
//       background-color: var(--table-header-background);
//       border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
//       .MuiTableCell-head {
//         color: var(--ion-text-light);
//         border: 0px !important;
//         font-family: Bebas Neue;
//         font-style: normal;
//         font-weight: 500;
//         font-size: 18px;
//         line-height: 106.7%;
//         white-space: nowrap;
//         padding: 2px 16px;

//         .MuiIconButton-root {
//           opacity: 1;
//           .MuiTableSortLabel-root .MuiSvgIcon-root {
//             color: #fff !important;
//             opacity: 1 !important;
//           }
//         }
//       }
//     }
//   }

//   .MuiTableBody-root {
//     background-color: var(--table-body-background);
//     .MuiTableRow-body {
//       background-color: var(--table-body-background);
//     }
//     .MuiTableCell-body {
//       color: var(--ion-text-light);
//       border: 0px !important;
//       cursor: default;
//       white-space: nowrap;
//       font-size: 14px;
//       background-color: var(--table-body-background);
//     }
//     .MuiSvgIcon-root {
//       color: var(--ion-text-light);
//     }

//     .back-odd-row {
//       background-color: var(--back-odd-background);
//     }

//     .lay-odd-row {
//       background-color: var(--lay-odd-background);
//     }

//     .profit-bet {
//       color: var(--ion-color-success) !important;
//     }

//     .loss-bet {
//       color: var(--ion-color-lost) !important;
//     }
//   }
// }

// @media only screen and (max-width: 720px) {
//   .MuiPaper-root {
//     margin-top: 16px;
//     .MuiTable-root {
//       .MuiTableHead-root {
//         .MuiSvgIcon-root {
//           font-size: 20px;
//         }
//       }
//       .MuiTableBody-root {
//         .MuiTableCell-body {
//           font-size: 12px;
//           min-height: 42px;
//           // width: 100%;
//         }
//         .MuiSvgIcon-root {
//           font-size: 18px;
//           margin-bottom: -2px;
//         }
//       }
//     }
//   }
//   .MuiPaper-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
//     font-size: 16px !important;
//   }
// }

// .dense-table-ctn {
//   .MuiTable-root {
//     .MuiTableRow-root .MuiTableCell-root {
//       padding: 6px 8px;
//     }
//   }
// }
// .MuiTable-root {
//     border:1px solid (--grey-bg);
// }
// .MuiTableCell-head{
//     border: 1px solid var(--grey-bg);
// }
.MuiTableCell-sizeSmall {
    font-family: 'Roboto Condensed', sans-serif !important;
    padding: 0px;
    .MuiTableCell-head {
        background-color: var(--grey-bg) !important;
        font-family: 'Roboto Condensed', sans-serif !important;
        font-weight: bolder;
    }
}