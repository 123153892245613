.modal-close-btn {
  position: absolute !important;
  top: 8px;
  right: 8px;

  .MuiIconButton-label {
    svg {
      fill: var(--ion-text-light);
    }
  }
}

.MuiDialog-paperWidthMd {
  max-width: 1185px !important;
}

.dark-close-btn {
  color: var(--dialog-header-background) !important;
}

.dark-colose-btn .MuiIconButton-root {
  color: var(--dialog-header-background) !important;
}

.MuiDialog-paper {
  .MuiDialogContent-root {
    .modal-body {
      padding: 14px !important;
    }
  }

  .MuiDialogActions-root {
    justify-content: center;
  }
}
.MuiDialogActions-root {
  justify-content: center !important;
}

.casino-modal {
  .MuiDialog-container {
    height: 99% !important;
    margin: 0px;

    .MuiDialogContent-root {
      padding: 10px !important;

      .dc-iframe-ctn {
        padding: 0 !important;
      }
    }

    .ind-casino-iframe-ctn {
      padding: 0;
    }
  }
}

.MuiDialog-paperScrollPaper {
  max-height: calc(100% - 26px) !important;
  margin-top: 0 !important;
}

@media screen and (max-width: 720px) {
  .betslip-modal {
    display: flex;

    .MuiDialogTitle-root {
      display: none !important;
    }

    .modal-close-btn {
      display: none;
    }

    .MuiDialog-container {
      height: 45% !important;
      margin: 6px;

      .MuiDialogTitle-root {
        display: flex;
        align-items: center;

        .MuiTypography-root {
          font-size: 18px !important;
        }
      }

      .MuiDialogContent-root {
        padding: 0 !important;

        .exch-betslip-ctn {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .casino-modal {
    .MuiDialog-container {
      height: 99% !important;
      margin: 6px;

      // .MuiDialogTitle-root {
      //   display: flex;
      //   align-items: center;

      //   .MuiTypography-root {
      //     font-size: 18px !important;
      //   }
      // }

      .MuiDialogTitle-root {
        height: 0;
      }

      .MuiDialogContent-root {
        padding: 0 !important;

        .dc-iframe-ctn {
          padding: 0 !important;
          height: 100% !important;
        }
      }
    }
  }

  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 10px) !important;
    margin: 0 6px !important;
  }
}
