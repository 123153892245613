img{object-fit: unset;}
.home-page-ctn {
  padding-bottom: 50px;
  
  .events-table-content {
    padding: 0px;
    .events-table {
      .MuiTableCell-root {
        padding: 0px;
        border-top: 1px solid #ccc;
        padding: 0px 0px 0px 0px;
        font-weight: bold;
        font-size: 13px;
        color: var(--site-color);
        font-family: 'Roboto Condensed', sans-serif !important;
      }
      .event-table-body {
        .events-table-msg-text {
          color: var(--site-color);
          background-color: #ccc;
          padding: 4px 15px;
        }
      }
    }
  }
  .home-grid {
    background: var(--primary-color);
    .sports-book {
      display: flex;
      justify-content: center;
    }
    .live-game {
      // box-shadow: 0 25px 8px -8px;
      border-radius: 4px;
      cursor: pointer;
      padding: 4px 4px !important;
      width: 264px;
      @media screen and (max-width: 720px) {
        padding: 6px 2px !important;
      }

      //height: 150px !important;
      .grid-images {
        cursor: pointer;
        overflow: hidden;
        //max-height: 110px;
        width: 264px;
        height: 150px;
        object-fit: fill;
       
      }
      .grid-image-name {
        background: var(--theme-gradient) !important;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 0px 0px 4px 4px;
        padding: 6px;
        font-size: 11px;
        bottom: 5px;
        height: 20px;
        color: #fff !important;
        display: flex;
        justify-content: center;
        align-items: center !important;
        font-family: 'Roboto Condensed', sans-serif !important;
        width: 100%;
        object-fit: unset;
        // position:absolute;
       
      }
    }
  }
}

// @media screen and (max-width: 720px) {
//   .home-page-ctn {
//     .page-banner-ctn {
//       .banner-left-section {
//         padding: 6px;

//         .header {
//           font-size: 16px;
//         }

//         .sub-text {
//           font-size: 16px;

//           .color-block {
//             padding: 14px;
//           }
//         }
//       }
//     }

//     .banner-images {
//       height: 125px;
//     }

//     .img-btn {
//       display: none;
//     }

//     .web-banners {
//       display: none !important;
//     }

//     .home-grid {
//       padding: 12px;
//     }

//     .more-link {
//       margin: 10px 0 !important;
//       padding: 0;
//       font-size: 14px !important;
//       font-weight: 600;
//       line-height: 15px;
//       ion-icon {
//         margin-bottom: -2px;
//       }
//     }

//     .live-games-text,
//     .sports-text {
//       margin: 0px !important;
//       padding-left: 0px;
//       font-size: 12px !important;
//       line-height: 14px;
//     }

//     .live-games,
//     .sports {
//       .live-games-header,
//       .sports-header {
//         padding: 0px;
//       }
//     }
//   }
// }


@media screen and (max-width: 720px) {
  .home-page-ctn {
    padding-bottom: 50px;
    .home-grid {
      background: var(--primary-color);
      padding: 5px 0px;
      .sports-book {
        display: flex;
        justify-content: center;
      }
      .live-game {
        // box-shadow: 0 25px 8px -8px;
        border-radius: 4px;
        cursor: pointer;
        padding: 4px 4px;
        //height: 120px !important;
        .grid-images {
          cursor: pointer;
          overflow: hidden;
          //max-height: 80px;
        }
        .grid-image-name {
          background: var(--theme-gradient) !important;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          border-radius: 0px 0px 4px 4px;
          padding: 6px;
          font-size: 10px;
          bottom: 5px;
          height: 20px;
          font-family: 'Roboto Condensed', sans-serif !important;
          // width: 100%;
          // position:absolute;
        }
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .event-into-icons{
    padding: 0px 5px 0px 0px;
    display: flex;
  }
  }