.MuiPickersBasePicker-container {
  background: var(--primary-color) !important;
  color: var(--site-color) !important;
  .MuiTypography-colorInherit{
    color: var(--site-color) !important;
  }
  .MuiPickersSlideTransition-transitionContainer > *{
    color: var(--site-color) !important;
  }
  .MuiSvgIcon-root{
    color: var(--site-color);
  }
  .MuiPickersBasePicker-pickerView {
    .MuiPickersCalendarHeader-switchHeader {
      margin: 0px;
      padding: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.1);
      .MuiPickersCalendarHeader-iconButton {
        background: var(--primary-color) !important;
        color: var(--site-color) !important;
        opacity: 1;
        border-radius: 4px;
      }
    }
    .MuiPickersCalendarHeader-daysHeader {
      .MuiPickersCalendarHeader-dayLabel {
        padding-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: var(--site-color) !important;
      }
    }
    .MuiPickersCalendar-transitionContainer {
      .MuiIconButton-root {
        color: var(--site-color) !important;
        opacity: 1;
        border-radius: 4px;
      }
      .MuiPickersDay-hidden {
        color: var(--site-color) !important;
      }
      .MuiPickersDay-daySelected {
        background: var(--primary-color) !important;
        color: var(--site-color) !important;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .MuiPickersBasePicker-container {
    .MuiPickersBasePicker-pickerView {
      min-width: 225px;
      max-width: 230px;
      min-height: 225px;
      max-height: 230px;
      overflow: hidden;
      .MuiPickersCalendarHeader-switchHeader {
        margin: 0px;
        padding: 4px;
        .MuiPickersCalendarHeader-iconButton {
          padding: 4px;
        }
      }
      .MuiPickersDay-day {
        width: 32px;
        height: 32px;
        margin: 0px;
      }
      .MuiPickersCalendar-transitionContainer {
        min-height: 162px;
      }
    }
  }
}
