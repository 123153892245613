@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
@import '../src/assets/global_styles/index.scss';
@import './scorcardtheme.css';


.router-ctn {
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  .center-router-ctn {
    display: inline-block;
    width: 100%;

    padding-bottom: 50px;
    @media screen and (max-width:720px){
      // padding:20px 20px 50px;
    }
  }
}

.Toastify__toast-container--top-center {
  top: 3em;
}

html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dev-tools-msg-modal {
  .modal-content-ctn {
    background: var(--ion-background) !important;
    border: 5px solid var(--ion-color-primary);

    .dev-tools-warning-msg {
      padding: 20px;
      font-size: 24px;
      color: #fff;
    }
  }
}
